<template>
    <div class="col-right">
        <a-spin :spinning="spinning">
            <a-card title="我的商品库">
<!--                <a href="#" slot="extra" class="primary-text-color">生成选品商城链接</a>-->
                <a-form layout="inline">
                    <a-form-item label="商品名称">
                        <a-input v-model="queryParams.goods_title"/>
                    </a-form-item>
                    <a-form-item label="商品编号">
                        <a-input v-model="queryParams.goods_code"/>
                    </a-form-item>
                    <a-form-item label="状态">
                        <a-select v-model="queryParams.status" placeholder="请选择" style="width: 200px;" allowClear>
                            <a-select-option value="1">
                                在售
                            </a-select-option>
                            <a-select-option value="2">
                                下架
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                    <a-form-item label="商品分类">
                        <a-select style="width: 120px" placeholder="请选择" @change="changeCategory1" allowClear>
                            <a-select-option v-for="(item, idx) in categoryList" :value="item.id" :key="idx">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                        <a-select style="width: 120px" placeholder="请选择" @change="changeCategory2" allowClear>
                            <a-select-option v-for="(item, idx) in category2List" :value="item.id" :key="idx">
                                {{item.title}}
                            </a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="毛利率%">
                        <a-form-item
                                :style="{ display: 'inline-block' }"
                        >
                            <a-input v-model="queryParams.grossProfitRate1" style="width: 100%" placeholder="最小值" />
                        </a-form-item>
                        <span :style="{ display: 'inline-block', textAlign: 'center' }">
                        -
                      </span>
                        <a-form-item :style="{ display: 'inline-block', marginLeft: '16px'}">
                            <a-input v-model="queryParams.grossProfitRate2" style="width: 100%" placeholder="最大值" />
                        </a-form-item>
                    </a-form-item>
                    <a-form-item label="分销价">
                        <a-form-item
                                :style="{ display: 'inline-block' }"
                        >
                            <a-input v-model="queryParams.base_price1" style="width: 100%" placeholder="最小值" />
                        </a-form-item>
                        <span :style="{ display: 'inline-block', textAlign: 'center' }">
                        -
                      </span>
                        <a-form-item :style="{ display: 'inline-block', marginLeft: '16px'}">
                            <a-input v-model="queryParams.base_price2" style="width: 100%" placeholder="最大值" />
                        </a-form-item>
                    </a-form-item>
                    <a-row>
                        <a-form-model-item>
                            <a-button type="primary" @click="searchGoods">
                                查询
                            </a-button>
                            <a-button style="margin-left: 10px;" html-type="reset">
                                重置
                            </a-button>
                        </a-form-model-item>
                    </a-row>
                </a-form>
            </a-card>
            <a-card>
                <a-row class="mb-md">
                    <a-col span="8">
                        <a-button @click="mulitRemove" :disabled="selectedRowKeys.length == 0">移除</a-button>
                    </a-col>
                    <a-col span="16" style="text-align: right;">
                        <a-button style=" margin-right: 16px;" @click="exportFile">导出</a-button>
                        <a href="/#/download">查看导出记录</a>
<!--                        <a-button style=" margin-right: 16px;">批量导入商品</a-button>-->
<!--                        <a href="#">查看导入记录</a>-->
                    </a-col>
                </a-row>

                <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
                         :columns="columns" :data-source="goodsList" :pagination="false">
                    <div slot="goods_title" slot-scope="text, record">
                     <span @click="() => {$jumpTo('/goods/'+record.id+'/type/'+record.goods_type);}">
                          <div class="name-col ng-star-inserted"
                               style="display: flex; white-space: normal; cursor: pointer;"><img :src="record.logo">
                          <dl>
                              <dt>{{ record.title }}</dt>
                              <dd>商品编号：{{ record.id }}</dd>
                              <dd>分类：{{ record.cates }}</dd>
                          </dl>
                      </div>
                      </span>
                    </div>
                    <div slot="base_price" slot-scope="text, record">
                        <p>{{record.base_price}}</p>
                        <p>{{parseFloat((record.suggest_price - record.base_price) /
                            record.suggest_price * 100  ).toFixed(2)}}%</p>
                    </div>
                    <template slot="operation" slot-scope="text, record, index">
                        <div class="editable-row-operations">
                    <span>
                      <a class="primary-text-color" @click="() => remove(index)">移除</a>
                    </span>
                        </div>
                    </template>

                </a-table>

                <div class="pagination">
                    <a-pagination :current="queryParams.page" :total="total" :pageSize="queryParams.pageSize"
                                  @change="changePage" :show-total="total => `共 ${total} 条记录`"/>
                </div>

            </a-card>
            <a-modal :visible="exportTip" width="400px" :footer="false" :closable="false">
                <div style="overflow: hidden">
                    <div style="font-size: 18px;"><a-icon type="check-circle" style="color:#52c41a;" />申请导出成功</div>
                    系统正在后台下载文件中...<br />
                    请查看导出记录进行文件下载
                    <div style="float: right; margin-top: 24px;">
                        <a-button @click="()=> {exportTip = false;}" style="margin-right: 16px;">我知道了</a-button>
                        <a-button type="primary" @click="onOk">查看导出记录</a-button>
                    </div>
                </div>
            </a-modal>
        </a-spin>
    </div>
</template>

<script>
    import service from "../../../utils/request";

    export default {
        name: "my-goods",
        data() {
            return {
                spinning: false,
                exportTip: false,
                columns: [
                    {
                        title: '商品',
                        dataIndex: 'goods_title',
                        scopedSlots: {customRender: 'goods_title'},
                        width: '320px'
                    },
                    {
                        title: '建议零售价(元)',
                        dataIndex: 'suggest_price',
                    },
                    {
                        title: '市场价(元)',
                        dataIndex: 'market',
                    },
                    {
                        title: '分销价(元)预估毛利率',
                        dataIndex: 'base_price',
                        scopedSlots: {
                            customRender: 'base_price'
                        }
                    },
                    {title: '操作', key: 'operation', scopedSlots: {customRender: 'operation'}, fixed: 'right'},
                ],
                goodsList: [],
                categoryList: [],
                category2List: [],
                selectedRowKeys: [], // Check here to configure the default column,
                queryParams: {
                    goods_title: '',
                    goods_code: '',
                    status: '',
                    cate1: 0,
                    cate2: 0,
                    page: 1,
                    pageSize: 10,
                    grossProfitRate1:'',
                    grossProfitRate2:'',
                    base_price1:'',
                    base_price2:'',
                },
                total: 0
            }
        },
        created() {
            this.getList();
            this.getCategoryTree();
        },
        methods: {
            onOk(){
                console.log('onOk')
                this.$jumpTo('/download');
            },
            exportFile(){
                this.spinning = true;
                let params = this.queryParams;
                params.export = 1;
                console.log(params);
                service.post(service.uri.goods.myLib, params).then(res => {
                    this.spinning = false;
                    console.log(res);
                    this.exportTip = true;
                });
            },
            changePage(e) {
                this.queryParams.page = e;
                this.getList();
            },
            searchGoods() {
                this.queryParams.page = 1;
                this.getList();
            },
            changeCategory1(e) {
                this.queryParams.cate1 = e;
                this.category2List = this.categoryList[e].children;
                console.log(e);
                console.log(this.categoryList[this.queryParams.cate1]);
            },
            changeCategory2(e) {
                this.queryParams.cate2 = e;
            },
            getCategoryTree() {
                service.post(service.uri.goods.getCategoryTree).then(res => {
                    if (res.code == 200) {
                        this.categoryList = res.data.list;
                    }
                })
            },
            onSelectChange(selectedRowKeys) {
                console.log('selectedRowKeys changed: ', selectedRowKeys);
                this.selectedRowKeys = selectedRowKeys;
            },
            mulitRemove() {
                if (this.selectedRowKeys.length == 0) {
                    this.$message.error("请选择移除数据");
                    return false;
                }
                let spu_ids = [], goods_type = [];
                this.selectedRowKeys.forEach(idx => {
                    spu_ids.push(this.goodsList[idx].id)
                    goods_type.push(this.goodsList[idx].goods_type)
                })

                this.spinning = true;
                this.removeFromMyLib(spu_ids, goods_type);
            },
            removeFromMyLib(spuIds, goods_type) {
                service.post(service.uri.goods.removeFromMyLib, {spu_ids: spuIds, goods_type: goods_type}).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.getList();
                    }
                })
                this.selectedRowKeys = [];
            },
            remove(idx) {
                console.log(idx)
                let goods = this.goodsList[idx];
                let id = goods.id;
                this.spinning = true;
                this.removeFromMyLib([id], [goods.goods_type]);
            },
            getList() {
                this.spinning = true;
                service.post(service.uri.goods.myLib, this.queryParams).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.goodsList = res.data.list;
                        this.total = res.data.total;
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../assets/css/common";

    .mb-md {
        margin-bottom: 16px;
    }


    .name-col {
        img {
            display: block;
            width: 60px;
            height: 60px;
        }

        dl {
            margin: 0;
            list-style: none;
            padding: 0 0 0 10px;
            flex: 1;
            width: 0;

            dt {
                font-size: 14px;
                line-height: 24px;
                color: #333;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            dd {
                line-height: 18px;
                font-size: 12px;
                color: #666;
            }
        }
    }
</style>